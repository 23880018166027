import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { columnDefs } from './peopleTableHeader';
import { ColDef } from 'ag-grid-community';
import { toast } from 'react-toastify';
import { apiResponse } from '../../utility/commonMethods';
import './index.scss';

const ViewVPMapping: React.FC = () => {
  const [rowData, setRowData] = useState<any[]>([]);
  const [peopleColDefs, setpeopleColDefs] = useState([{ headerName: 'Login Name', field: 'login_name', tooltipField: 'login_name' }]);
  const defaultColDef: any = useMemo<ColDef>(() => {
    return {
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      sortable: true,
      wrapText: false,
      filter: true,
      filterParams: { maxNumConditions: 1 },
      resizable: true,
    };
  }, []);
  const getPeopleMappingData = async () => {
    try {
      const res = await apiResponse('get', 'get-vp-mapping-diffs-details', [], {});
      if (res?.data.status === 'SUCCESS') {
        if (res.data.data.length > 0) {
          const rowData = res.data.data.map((item: any) => {
            item.state = '';
            return item;
          });
          setRowData(JSON.parse(JSON.stringify(rowData)));
        } else {
          toast.error('People VP Mapping Data not available!!');
        }
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
  };
  useEffect(() => {
    getPeopleMappingData();
    setpeopleColDefs([...peopleColDefs, ...columnDefs]);
  }, []);

  return (
    <div className="ag-theme-alpine" style={{ height: '75vh', width: 'auto' }}>
      <div className="mapping-title">VP Mapping Table</div>
      <AgGridReact
        rowData={rowData}
        columnDefs={peopleColDefs}
        defaultColDef={defaultColDef}
        overlayNoRowsTemplate='<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
      />
    </div>
  );
};

export default ViewVPMapping;
